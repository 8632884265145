import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import SEO from '../../components/SEO'
import Footer from '../../components/Footer'
import '../../assets/styles.css'
import '../../old/pages/legal.css'
import Page from '../../old/templates/Page'
import PageSection from '../../old/templates/PageSection'

class BroadcasterTerms extends React.Component {
    render() {
        return (
            <React.Fragment>
            <SEO />
            <Page
                className={classNames('legal', 'terms', this.props.className)}
                headerTitle="Live365 Broadcaster, LLC Agreement & Terms of Service"
            >
                <PageSection>
                    <p>
                        Please read these Terms of Service carefully before
                        using LIVE365 BROADCASTER as they form an Agreement
                        between you (“Broadcaster”) and Live365 Broadcaster,
                        LLC. (“Live365”), a Delaware LLC with its principal
                        place of business at 1340 Monticello Avenue, Hermitage,
                        PA 16148.
                    </p>
                    <p>
                        BY USING LIVE365 BROADCASTER, YOU ARE AGREEING TO BE
                        BOUND BY THESE TERMS OF SERVICE INCLUDING COMPLYING WITH
                        ANY AND ALL APPLICABLE LAWS AND REGULATIONS AT ALL TIMES
                        DURING YOUR USE.  IF YOU DO NOT AGREE TO THESE TERMS, DO
                        NOT USE THE SERVICE.
                    </p>
                    <p>
                        This Terms of Service may be amended and modified by
                        Live365 at its sole discretion at any time by posting
                        changes to the Live365 Broadcaster Terms of Service
                        at https://broadcaster.live365.com/tos .
                        Broadcaster&#39;s use of Services after such posting
                        shall constitute Broadcaster&#39;s acceptance of any
                        such amendments or modifications.
                    </p>
                </PageSection>

                <PageSection>
                    <h3>Recitals</h3>
                    <p>
                        WHEREAS, Live365 provides subscription based Internet
                        broadcasting tools and services for the programming,
                        distribution and monetization of Broadcaster’s
                        Internet-based audio streams including but not limited
                        to content management; playlist scheduling; hosting and
                        bandwidth; audio players for web, mobile apps and
                        devices; impression management; audience, performance
                        and impression tracking and reports; and statistical
                        tools (collectively, the “Services”) according to the
                        selected Broadcaster Package (the “Package”) on your
                        behalf (“Broadcaster”).  Live365 reserves the right to
                        modify the Services and the Packages at any time.
                    </p>

                    <p>
                        WHEREAS, Live365 also provides, on a limited basis,
                        certain proprietary technology, including software,
                        processes, algorithms, user interfaces, designs,
                        application programming interfaces (“APIs”) and other
                        tangible or intangible technical material or information
                        (collectively, inclusive of all future modifications to
                        or derivations of the same, the “Live365 Technology”) to
                        its Broadcaster customers in order to facilitate such
                        customers’ use of the Services;
                    </p>
                    <p>
                        WHEREAS, Live365 has secured certain third party rights
                        (“Covered Royalties”) for the transmission of
                        Broadcaster’s Content;
                    </p>
                    <p>
                        WHEREAS, Broadcaster desires to prepare and transmit
                        Broadcaster’s Content and to otherwise use the Services
                        and the Live365 Technology in accordance with the terms
                        and conditions set forth herein;
                    </p>
                    <p>
                        WHEREAS Broadcaster desires to provide access to
                        Broadcaster’s Content through any and all end-user
                        interfaces now or hereafter known and devices capable of
                        receiving streaming transmissions from Live365 servers,
                        including, without limitation, transmissions via the
                        Internet and/or via mobile/wireless technologies; and
                    </p>
                    <p>
                        WHEREAS, Broadcaster has obtained any and all required
                        third-party rights and licenses authorizing the
                        transmission, use or other exploitation of Broadcaster’s
                        Content (and to make any and all payments associated
                        with obtaining those rights and licenses), in connection
                        with the Services.
                    </p>
                    <p>
                        NOW THEREFORE, in consideration of the foregoing
                        recitals and the mutual promises and agreements set
                        forth in this Agreement, the parties hereto agree as
                        follows:
                    </p>
                    <ol>
                        <li>
                            Services. Live365 shall provide the Services for
                            Broadcaster’s transmission of Broadcaster’s Content,
                            subject to the terms and conditions contained herein
                            (including, without limitation, according to the
                            applicable Broadcasting Package (“Package”)
                            contained in Schedule B which Broadcaster selected
                            upon signup and made a part hereof by this
                            reference). “Broadcaster’s Content” means any and
                            all uploaded program elements, whether audio
                            (including sound recordings), text, photographs,
                            pictures, graphic, live, looped or archived
                            recordings, artwork, comments, and any other
                            programming, content, data or information
                            transmitted by Broadcaster through the Services
                            (including, without limitation, Broadcaster’s
                            individual playlists). Live365 reserves the right to
                            modify the Services at any time.
                        </li>
                        <li>
                            Broadcaster License Grant. Broadcaster grants
                            Live365 the royalty-free, non-exclusive, perpetual
                            right and license, throughout the world, to use,
                            exploit, transmit, reproduce, perform publicly,
                            display publicly, perform digitally, distribute and
                            syndicate, by any means and any medium now known or
                            hereafter devised, Broadcaster’s Content, in whole
                            or in part, for any and all purposes (including,
                            without limitation, in connection with the
                            exploitation, marketing, advertisement or promotion
                            of Live365 and/or the Services), to the full extent
                            permitted by law, and the right to grant to third
                            parties the right to do any or all of the foregoing.
                            Without limiting the foregoing provisions of this
                            section, Live365 shall have the royalty-free,
                            non-exclusive, perpetual right and license,
                            throughout the world, to transmit and permit its end
                            users, and other website, applications or network
                            operators and their end users, to access
                            Broadcaster’s Content via any third party website or
                            application, or by any other means, medium, method,
                            device or process now or hereafter known.
                        </li>
                        <li>
                            Third-Party Licensing and Royalties.  Live365 will
                            secure selected sound recording and musical work
                            rights in connection with Live365’s transmission of
                            Broadcaster’s Content in the USA, United Kingdom and
                            Canada through Live365’s website and mobile players
                            (“Covered Royalties”).  The Covered Royalties are
                            defined in Live365’s agreements with SoundExchange,
                            PPL and Re:Sound the entity responsible for
                            collecting and distributing royalties for the
                            digital performance of sound recordings; the
                            American Society of Composers, Authors, and
                            Publishers (“ASCAP”); Broadcast Music, Inc. (“BMI”);
                            and the Society of European Stage Authors and
                            Composers (“SESAC”); PRS for Music, and the Society
                            of Composers, Authors and Music Publishers of Canada
                            (“SOCAN”); collectively, the “Performing Rights
                            Organizations” for musical works.  Live365’s
                            coverage is provided through finalized agreements,
                            interim agreements, and consent decrees with the
                            respective collection societies.  Broadcaster will
                            be solely responsible for obtaining any and all
                            additional required licenses for the worldwide
                            distribution of Broadcasters Content including but
                            not limited to sound recording and musical works
                            rights. Broadcaster will also be responsible for any
                            and all royalties or other payments that may be
                            required in connection with these and any other
                            third-party licenses (including, without limitation,
                            any and all royalties or other payments that may be
                            due to the foreign performing rights organizations).
                        </li>
                        <li>
                            Trademark License. Live365 grants Broadcaster a
                            non-exclusive, non-sublicensable, non- transferable,
                            royalty-free, worldwide license to use and display
                            solely those trademarks, service marks and/or logos
                            that are listed at the
                            URL https://live365.com/marks (the “Live365 Marks”)
                            for the sole purpose of advertising and promoting
                            Broadcaster’s Content that is transmitted via the
                            Services. Broadcaster grants Live365 a
                            non-exclusive, royalty-free, worldwide license to
                            use any trade name, trademark, service mark, logo or
                            other name or mark that is owned or licensed by
                            Broadcaster (including, if applicable, Broadcaster’s
                            radio call signs) (the “Broadcaster Marks”) in or in
                            connection with the marketing, advertisement or
                            promotion of Live365 or the Services, by any means
                            and in any medium now known or hereafter devised,
                            whether by Live365 or by any third party designated
                            by Live365 (including, without limitation, in any
                            directory listing of Live365’s broadcasters).
                            Neither party shall take any action that would
                            impair, denigrate, convey ownership in, tarnish or
                            damage the other party’s rights with respect to the
                            Live365 Marks or the Broadcaster Marks, as
                            applicable, or the proprietary products or services
                            of the other party. All use of the Live365 Marks
                            shall be in accordance with Live365’s then-current
                            trademark usage guidelines.
                        </li>
                        <li>
                            Live365 Technology License. Live365 grants
                            Broadcaster a non-exclusive, non-sublicensable, and
                            non-transferable license to use the Live365
                            Technology for the sole purpose of enabling
                            Broadcaster to use the Services. Broadcaster agrees
                            that all ownership rights in the Live365 Technology,
                            including all intellectual property rights, remain
                            owned by Live365 and/or its licensors and agrees not
                            to: (i) modify, adapt, alter, translate or create
                            derivative works from the Live365 Technology; (ii)
                            decompile, reverse engineer, disassemble, tamper or
                            otherwise reduce the Live365 Technology to human
                            readable form nor permit any third party to do so;
                            and (iii) sublicense, lease, sell, rent, loan,
                            distribute copies of or otherwise transfer the
                            Live365 Technology to any third party. Broadcaster
                            undertakes to use its best efforts to protect and
                            keep confidential the Live365 Technology.
                        </li>
                        <li>
                            Broadcaster’s Obligations. Broadcaster shall at all
                            times: (a) comply with all applicable laws, rules
                            and regulations and Live365’s policies (as amended
                            from time to time), including, without limitation:
                            (i) license restrictions (if any) imposed by any
                            entity on the transmission of Broadcaster’s Content,
                            which restrictions (if any) shall be disclosed to
                            Live365 in writing prior to the Term; (ii) Live365’s
                            Terms of Service and Privacy Policy; (iii)
                            applicable data protection laws, rules and
                            regulations, if any, in any applicable jurisdiction;
                            and (iv) the Digital Millennium Copyright Act
                            (“DMCA”)  including compliance with the Performance
                            Complement; (b) comply with the technical and other
                            requirements made by Live365 for the performance of
                            the Services, including: (i) to transmit
                            Broadcaster’s Content to Live365 at a speed
                            designated by Live365, (ii) to upload and maintain
                            sound files for Broadcaster’s Content in accordance
                            with Live365’s specifications (as the same may
                            change from time to time); (iii) to ensure that such
                            copyrighted content is tagged Type=Music and
                            contains proper ID3 tagging with respect to each
                            sound recording on each playlist and metadata push
                            corresponding to Broadcaster’s Content; and (iv) to
                            ensure that Broadcaster’s promotion includes a
                            “Live365” logo – or, upon Live365’s request, a
                            similar logo – in close proximity to the link(s) to
                            Live365; and (c) inform Live365 in writing
                            immediately upon receipt of notice from any third
                            party alleging that Broadcaster’s Content or any
                            part of it: (i) infringes any third party copyright,
                            trademark, trade secret or any publicity, privacy or
                            patent right or other similar personal or
                            proprietary rights; or (ii) is in breach of
                            Live365’s terms of use or privacy policy.
                        </li>
                        <li>
                            Term; Termination. The term of this Agreement shall
                            commence on the date Broadcaster’s acceptance of
                            these Terms of Service and, subject to the parties’
                            rights of termination below, shall continue
                            initially for a period of one (1) month (the
                            “Initial Term”); provided, however, that (a)
                            thereafter, the Term shall continue for successive
                            one (1) month periods (each a “Renewal Period”)
                            unless either party gives written notice (which
                            shall be deemed effective upon receipt), at least
                            thirty (30) days prior to the end of the Initial
                            Term or any Renewal Period, as applicable, that the
                            Agreement shall be terminated, and (b) either party
                            may terminate this Agreement at any time by written
                            notice to the other party (which shall be deemed
                            effective upon receipt) if the other party
                            materially breaches any of its agreements,
                            covenants, warranties, representations, indemnities
                            or obligations under this Agreement, and (c)
                            notwithstanding the above, either Party may
                            terminate this Agreement at any time by written
                            notice to the other party (which shall be deemed
                            effective upon receipt) during “Free Trial” and/or
                            any “Specials”. Additional free trial and/or
                            specials terms and conditions may appear on Live365
                            Website. Any such additional terms and conditions
                            are incorporated into this Agreement by reference
                            and are legally binding. From time to time herein,
                            the Initial Term and any Renewal Period shall be
                            referred to collectively as the “Term.” On
                            termination or expiry of this Agreement for any
                            reason, Live365 may cease transmission of
                            Broadcaster’s Content and shall have the right to
                            delete any and all of Broadcaster’s Content, data
                            and other materials on its servers. Upon termination
                            of this agreement as specified herein, except as
                            otherwise set forth in this Agreement, Live365 shall
                            have no obligation whatsoever to repay any amounts
                            (including payments in advance) received from
                            Broadcaster in respect of Services provided
                            hereunder, and Broadcaster shall have no further
                            obligation to make payments to Live365.
                        </li>
                        <li>
                            Fees and Payment. Broadcaster shall pay Live365: (a)
                            the applicable Setup Fee and initial Monthly Fees
                            (as set forth in the selected Package when
                            Broadcaster signed up); and (b) the applicable
                            recurring Monthly Fees (as defined in the Package),
                            as set forth below. From time to time herein, Setup
                            Fees and Monthly Fees are referred to collectively
                            as “Fees.” Live365 reserves the right to increase
                            the Monthly Fees on the provision of thirty (30)
                            days’ written notice to Broadcaster. The Service is
                            provided whether Broadcaster utilizes Services or
                            not. Non-utilization of the service does not affect
                            the Fees. Live365 shall charge Broadcaster (via
                            credit card, debit card or PayPal, as applicable)
                            charges for the Monthly Fees as they are due, in
                            which case Broadcaster acknowledges and agrees that:
                            (i) Broadcaster will have sufficient funds, or
                            sufficient credit, available in the applicable
                            account to fully pay all Monthly Fees when due
                            during the Term; (ii) Live365 is not responsible for
                            Broadcaster’s use of Broadcaster’s credit card,
                            debit card or PayPal account; and (iii) Broadcaster
                            must resolve any disputes concerning Broadcaster’s
                            credit card, debit card or PayPal account directly
                            with PayPal or the applicable financial institution.
                            In the event that Monthly Fees will not
                            automatically be charged to Broadcaster’s credit
                            card, debit card or PayPal account, Broadcaster
                            shall pay Live365 each applicable Monthly Fee (after
                            the initial Monthly Fee) by check within thirty (30)
                            days of the date of the applicable billing statement
                            from Live365. Broadcaster is responsible for payment
                            of all taxes, duties or levies imposed in relation
                            to the payment of any Fees, other than Live365’s
                            income taxes. If the Fees due to Live365 are not
                            paid within ten (10) days of the due date, Live365
                            may elect to charge interest on outstanding Fees at
                            the rate of 1.5% per month or the maximum amount
                            allowed by law, and may, at its election, terminate
                            this Agreement. Broadcaster must provide notice of
                            billing discrepancies within thirty (30) days from
                            the date of the applicable billing statement;
                            otherwise, Broadcaster shall be deemed to have
                            accepted the amounts due and releases Live365 from
                            any liability and claims of loss resulting from any
                            error or discrepancy. Any extensions on payment due
                            dates are at the sole discretion of Live365.
                        </li>
                        <li>
                            Automatic Upgrades and Overages. Live365 packages
                            come with an allotted amount of monthly TLH (total
                            listening hours) for listening in the United States,
                            United Kingdom and Canada. If a Broadcaster goes
                            over their current package’s monthly TLH then they
                            will be automatically upgraded to the next package
                            on the following billing date. If a Broadcaster is
                            on the highest tier of package then they will be
                            automatically billed an overage fee of $0.05 per
                            listening hour over that packages allotment of
                            monthly hours on their next billing period.
                        </li>
                        <li>
                            Refund Policy. No refunds will be given on Fees for
                            the Service. If refunds are given to the Broadcaster
                            it will be at the sole discretion of Live365.
                        </li>
                        <li>
                            Advertising Insertion and Revenue Share. Broadcaster
                            hereby grants to Live365, as part of the Services
                            and in Live365’s sole discretion, the exclusive
                            right to insert advertisements and network
                            identifications into Broadcaster’s Content
                            transmitted through Live365 (“Advertising Insertion
                            Rights”). In full and complete consideration of
                            Broadcaster’s grant of Advertising Insertion Rights,
                            Broadcaster shall be entitled to compensation
                            according to the terms set forth in the Advertising
                            Revenue Share Program, Schedule A attached hereto,
                            which is incorporated herein by this reference.
                        </li>
                        <li>
                            Broadcaster’s Warranties. Broadcaster represents and
                            warrants that: (a) Live365’s receipt, storage, use,
                            reproduction, transmission or retransmission of
                            Broadcaster’s Content or any part of it (“Handling”)
                            shall not violate or infringe any third party
                            intellectual property rights or any publicity or
                            privacy right; (b) Live365’s use of the Broadcaster
                            Marks, and any updates and modifications of them,
                            shall not violate or infringe any intellectual
                            property rights or any publicity or privacy right;
                            (c) Live365’s Handling of Broadcaster’s Content or
                            any part of it shall not breach any content
                            standards requirements or codes promulgated by any
                            relevant authority to whom Broadcaster and Live365
                            may be subject; (d) Broadcaster’s Content will not
                            contain or disclose processes or instructions which,
                            if implemented, might cause damage or injury to any
                            person or property; (e) Broadcaster’s Content, if
                            unsuitable for minors, will be marked or clearly
                            identified by warnings that it is unsuitable in
                            accordance with current industry standards, content
                            requirements or codes of practice promulgated by the
                            relevant authority; (f) Broadcaster’s Content
                            (inclusive of both sound recordings and any
                            underlying musical compositions or literary works)
                            is either owned by and original to Broadcaster or
                            has been lawfully obtained and licensed from any and
                            all applicable third parties; (g) Broadcaster has
                            authority or has obtained any and all necessary
                            approvals or licenses excluding Covered Royalties
                            (compulsory or otherwise, and including, without
                            limitation, all required licenses from any
                            Performing Rights Organization,), and has made or
                            will make any associated required payments, for the
                            storage, use, reproduction, distribution,
                            exploitation, performance, transmission and
                            retransmission of Broadcaster’s Content or any part
                            of it over the Internet and/or via wireless
                            networks, and that such authority, approval or
                            license, if any, is current and in full force and
                            effect; (h) Broadcaster’s Content, or any part
                            thereof, does not contain material that is
                            defamatory, libelous, obscene or otherwise unlawful;
                            (i) no litigation is pending or threatened with
                            respect to Broadcaster’s Content or any part of it;
                            (j) all service or product claims made by
                            Broadcaster pursuant to or in connection with this
                            Agreement shall be true and correct and fully
                            substantiated in accordance with applicable
                            government laws and regulations, and Broadcaster
                            shall not perform any act (or fail to perform any
                            act) in violation of any government law, statute, or
                            regulation; (k) Broadcaster has read and understood
                            the content of Live365’s Terms of Use and Privacy
                            Policy https://live365.com/terms-of-
                            service, https://broadcaster.live365.com/tos,
                            and https://live365.com/privacy; and (l) Broadcaster
                            shall at all times comply with the provisions of
                            applicable laws, rules and regulations and with
                            Live365’s policies, including without limitation
                            Live365’s Terms of Use and Privacy Policy. For the
                            avoidance of doubt, Live365 has no responsibility
                            for the content of Broadcaster’s Content and no
                            obligation to review, edit or monitor such content.
                        </li>
                        <li>
                            Assumption of Liability. Without limiting the
                            generality of Sections 3.6.10 and 15, all
                            broadcasting, transmission or other exploitation of
                            any of Broadcaster’s Content via the Services is at
                            Broadcaster’s sole risk, and Broadcaster assumes
                            full responsibility (financial or otherwise) for any
                            and all claims or suits that may be brought alleging
                            any infringement of any third-party right
                            (including, without limitation, any
                            intellectual-property right) arising from such
                            broadcasting, transmission or other exploitation of
                            Broadcaster’s Content.
                        </li>
                        <li>
                            Limitation of Liability. IN NO EVENT SHALL LIVE365
                            BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT,
                            INCIDENTAL, SPECIAL OR EXEMPLARY DAMAGES WHATSOEVER,
                            INCLUDING WITHOUT LIMITATION LOSS OF REVENUE OR
                            ANTICIPATED PROFITS, BUSINESS INTERRUPTION, LOSS OF
                            BUSINESS INFORMATION, AND THE LIKE, EVEN IF LIVE365
                            HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            IN NO EVENT SHALL LIVE365’S LIABILITY FOR MONETARY
                            DAMAGES ARISING OUT OF THIS AGREEMENT EXCEED THE
                            TOTAL AMOUNT RECEIVED BY LIVE365 FROM BROADCASTER
                            DURING THE TERM OF THIS AGREEMENT.
                        </li>
                        <li>
                            Press releases. Live365 shall have the right, in its
                            sole discretion, to issue any press release or make
                            any other public announcement or statement relating
                            to any terms or conditions of this Agreement or any
                            fact or surrounding circumstance relating to
                            Live365’s transmission of Broadcaster’s Content.
                            Notwithstanding anything to the contrary contained
                            herein, Broadcaster shall not have the right to
                            issue any press release or make any other public
                            announcement or statement relating to any terms or
                            conditions of this Agreement, or any fact or
                            surrounding circumstance relating to Live365’s
                            transmission of Broadcaster’s Content, without
                            Live365’s prior written consent.
                        </li>
                        <li>
                            Disclaimer. TO THE EXTENT PERMITTED BY APPLICABLE
                            LAW, LIVE365 PROVIDES THE LIVE365 TECHNOLOGY AND
                            SERVICES “AS IS,” WITHOUT WARRANTY OR REPRESENTATION
                            OF ANY KIND. LIVE365 EXPRESSLY DISCLAIMS ALL OTHER
                            WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                            LIMITATION, WARRANTIES OF MERCHANTABILITY AND
                            FITNESS FOR A PARTICULAR PURPOSE. THIS SECTION
                            CONTAINS THE ONLY WARRANTIES, EXPRESS OR IMPLIED,
                            MADE BY LIVE365. ANY AND ALL OTHER WARRANTIES,
                            EXPRESS OR IMPLIED, ARE EXPRESSLY EXCLUDED AND
                            DECLINED. LIVE365 DISCLAIMS ANY IMPLIED WARRANTIES,
                            PROMISES AND CONDITIONS OF MERCHANTABILITY, FITNESS
                            FOR A PARTICULAR PURPOSE, TITLE AND/OR
                            NON-INFRINGEMENT, WHETHER AS TO ANY LIVE365
                            TECHNOLOGY OR SERVICES (INCLUDING ANY TOOLS)
                            RENDERED BY LIVE365 AND/OR THE TECHNOLOGY DEPLOYED
                            IN CONNECTION THEREWITH. LIVE365 MAKES NO
                            REPRESENTATION THAT THE OPERATION OR PROVISION OF
                            THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE,
                            AND LIVE365 WILL NOT BE LIABLE FOR THE CONSEQUENCES
                            OF ANY INTERRUPTIONS OR ERRORS.
                        </li>
                        <li>
                            Indemnity. Broadcaster hereby indemnifies and holds
                            harmless, and agrees to defend against any
                            third-party claim or action brought against Live365
                            or any of its parent, subsidiary or affiliated
                            companies, its or their directors, officers,
                            employees, licensees, agents, attorneys, assigns or
                            independent contractors, from and against any and
                            all claims, actions, losses, liabilities, damages,
                            costs and expenses (including legal fees and costs)
                            arising out of or in connection with any claim(s)
                            that would constitute a breach of any warranty,
                            representation, covenant or agreement made by
                            Broadcaster in this Agreement (“Indemnified
                            Claims”). Live365 shall (at Broadcaster’s expense)
                            be entitled to participate in the defense of any
                            Indemnified Claim with its own counsel.
                        </li>
                        <li>
                            Discontinuance. Live365 reserves the right to cease,
                            discontinue, suspend or delete any transmissions of
                            Broadcaster’s Content if, in Live365’s sole
                            discretion and without notice or liability to
                            Broadcaster, Broadcaster’s Content is violated or
                            acted inconsistently with any provision of these
                            Agreement or any applicable law, rule or regulation,
                            or that Broadcaster has engaged in conduct that
                            Live365 reasonably determines to be inappropriate or
                            unacceptable. Live365 may in its sole discretion,
                            and at any time, discontinue providing Services, or
                            any part thereof, on notice to Broadcaster.
                        </li>
                        <li>
                            Assignment. This Agreement and any licenses and
                            rights herein granted are personal to Broadcaster
                            and shall not be assigned, transferred (by merger,
                            operation of law or in any other manner),
                            sublicensed or encumbered by Broadcaster without
                            Live365’s prior written consent. Any purported
                            transfer, assignment or delegation in violation of
                            the foregoing will be null and void and of no force
                            or effect. Live365 may assign, directly or
                            indirectly, all or part of its rights or obligations
                            under this Agreement without notice to Broadcaster
                            or obtaining prior consent from Broadcaster.
                        </li>
                        <li>
                            Notices (Electronic Communications). Any
                            communication in connection with this Agreement
                            shall be in writing and sent by fax or prepaid post
                            (unless the address is changed by a notice), To
                            Live365: at the address provided above, Attn:
                            General Counsel. To Broadcaster: at the address
                            provided by the Broadcaster. By using the Services,
                            Broadcaster consents to receiving certain electronic
                            communication from Live365. Broadcaster hereby
                            agrees that any notices, agreements, disclosures, or
                            other communications that Live365 sent to
                            Broadcaster electronically will satisfy any legal
                            communication requirements, including that such
                            communications be in writing.
                        </li>
                        <li>
                            Designated Agent. For Purposes of Section 512 Of The
                            Digital Millennium Copyright Act, Live365’s
                            Designated Agent contact information is: Live365
                            1340 Monticello Avenue Hermitage, PA 16148
                            dmca@live365.com
                        </li>
                        <li>
                            General. These Terms of Service, last updated
                            October 9, 2020, constitute the entire agreement
                            between the parties and supersedes all prior and
                            contemporaneous communications. If a court of
                            competent jurisdiction finds any provision of this
                            Agreement to be unenforceable, that provision shall
                            be enforced to the maximum extent permissible so as
                            to implement the intent of the parties, and the
                            remainder of the Agreement shall continue in full
                            force and effect. No waiver of any breach shall
                            constitute a waiver of any prior, concurrent or
                            subsequent breach of the same or any other
                            provision, and no waiver shall be effective unless
                            made in writing and signed by an authorized
                            representative of the waiving party. No
                            employer-employee relationship, partnership or joint
                            venture is created hereunder. The parties each agree
                            to take all actions and execute all documents as may
                            be necessary or desirable to carry out or implement
                            and give full effect to the provisions and intent
                            hereof. This Agreement and all matters arising under
                            it shall be governed by the applicable laws of the
                            USA, including U.S. intellectual property laws, and
                            the laws of Pennsylvania applicable to contracts
                            entered into and wholly to be performed therein,
                            without regard to choice of law rules. The parties
                            consent to the exclusive jurisdiction of and bring
                            any actions, suits or proceedings in the
                            Pennsylvania state courts located in Mercer County
                            or the federal courts located in the Middle District
                            of Pennsylvania.
                        </li>
                    </ol>
                </PageSection>

                <PageSection>
                    <h3>Schedule A</h3>
                    <h4>Advertising Revenue Share Program</h4>
                    <h5>NO ADVERTISING INSERTION OPTION</h5>
                    <p>
                        Pursuant to Section 11 of this Agreement, there is no
                        Revenue Share for Broadcasters that select a No
                        Advertising Insertion Option Package.
                    </p>
                    <h5>ADVERTISING INSERTION OPTION</h5>
                    <p>
                        Pursuant to Section 11 of this Agreement and in full
                        consideration of Broadcaster’s exclusive grant of
                        Advertising Insertion Rights, Live365 will share 50% of
                        Net Advertising Receipts with Broadcaster.  Net
                        Advertising Receipts are the amounts actually received
                        by Live365 for the corresponding Broadcaster’s delivery
                        of advertising impressions during the period.
                    </p>
                    <p>
                        Broadcaster’s Revenue Share is calculated each month
                        according to the following formula:
                    </p>
                    <p>
                        50% of Net US Audio Advertising Receipts times
                        Broadcaster’s percentage of corresponding paid US
                        impressions delivered for the period.
                    </p>
                    <p>PLUS</p>
                    <p>
                        50% of Net Non US Audio Advertising Receipts times
                        Broadcaster’s percentage of corresponding paid Non US
                        impressions delivered for the period.
                    </p>
                    <p>
                        For illustration purposes, if in a particular month,
                        Live365 received $25,000 for delivery of 8.5M US audio
                        impressions in the particular month, a Broadcaster whose
                        stations delivered 1M of those impressions would be paid
                        $1,471 for these US impressions which represents 50% of
                        the corresponding US ad revenue delivered by this
                        station.  A similar calculation will be done on non US
                        revenue and impressions. Credits to Broadcaster’s
                        account will be made 90-120 days following end of
                        corresponding month and Broadcaster may request to
                        receive payment from Live365. Only those Broadcasters
                        whose Live365 accounts are current (i.e., active
                        Advertising Supported Package and not delinquent) are
                        eligible to receive payment from Live365.
                    </p>
                    <p>
                        Broadcaster is required to provide Live365 a minimum of
                        4 minutes per hour of commercials. A maximum of two (2)
                        minutes is allowed per ad break, with a separation of at
                        least 15 minutes in-between each ad break. An ad break
                        is defined as an event triggered in order to allow
                        Live365 to insert digital audio ads on the stream.
                         Broadcaster is not allowed to insert any third party
                        paid advertising in their station’s programming at any
                        time.
                    </p>
                    <p>
                        Live365 has the right to modify the terms of the
                        “Advertising Revenue Share Program” at any time. For the
                        latest terms and conditions about Live365’s “Advertising
                        Revenue Share Program” – including information about how
                        to receive your payment from Live365 – please refer to
                        Live365 “Advertising Revenue Share Program” details
                        page, which is available
                        at: https://broadcaster.live365.com/adshare
                    </p>
                </PageSection>

                <PageSection>
                    <h3>Schedule B</h3>
                    <h4>Broadcast Packages</h4>
                    <table>
                        <thead>
                            <tr>
                                <th />
                                <th>Broadcast 1</th>
                                <th>Broadcast 2</th>
                                <th>Broadcast 3</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Storage</th>
                                <td>25 GB</td>
                                <td>50 GB</td>
                                <td>100 GB</td>
                            </tr>
                            <tr>
                                <th>Listeners</th>
                                <td>unlimited</td>
                                <td>unlimited</td>
                                <td>unlimited</td>
                            </tr>
                            <tr>
                                <th>Bandwidth</th>
                                <td>unlimited</td>
                                <td>unlimited</td>
                                <td>unlimited</td>
                            </tr>
                            <tr>
                                <th>Max Bitrate</th>
                                <td>128 Kbps</td>
                                <td>128 Kbps</td>
                                <td>128 Kbps</td>
                            </tr>
                            <tr>
                                <th>Codecs</th>
                                <td>mp3</td>
                                <td>mp3</td>
                                <td>mp3</td>
                            </tr>
                            <tr>
                                <th>US, CA, and UK Streaming Hours</th>
                                <td>1500</td>
                                <td>3500</td>
                                <td>7000</td>
                            </tr>
                            <tr>
                                <th>Price with ads</th>
                                <td>$59/mo</td>
                                <td>$99/mo</td>
                                <td>$199/mo</td>
                            </tr>
                            <tr>
                                <th>Price without ads</th>
                                <td>$79/mo</td>
                                <td>$134/mo</td>
                                <td>$274/mo</td>
                            </tr>
                        </tbody>
                    </table>
                </PageSection>
            </Page>
            <Footer />
            </React.Fragment>
        )
    }
}

export default BroadcasterTerms
